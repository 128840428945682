import { useEffect, useState, useRef } from "react";

export const OsmosisScientist = ({className, style}) => {
    const [ratio, setRatio] = useState(document.body.clientWidth / document.body.clientHeight);
    
    useEffect(() => {
        const calcAspectRatio = () => {
            setRatio(document.body.clientWidth / document.body.clientHeight)
        }

        window.addEventListener("resize", calcAspectRatio)

        return () => {
            window.removeEventListener("resize", calcAspectRatio)
        }
    }, []);

    useEffect(() => {
        setRatio(() => document.body.clientWidth / document.body.clientHeight);
    }, [ratio]);

    const scientistRef = useRef();
    const [scientistLoaded, setScientistLoaded] = useState(false);
    const frontRef = useRef();
    const [frontLoaded, setFrontLoaded] = useState(false);
    useEffect(() => {
        // At first, load the compressed images and high-res images at the same time.
        // And if the high-res images loaded, remove the compressed images.
        if (scientistRef.current) {
            scientistRef.current.addEventListener("load", () => {
                setScientistLoaded(true);
            }, {
                once: true
            })
        }

        if (frontRef.current) {
            frontRef.current.addEventListener("load", () => {
                setFrontLoaded(true);
            }, {
                once: true
            })
        }
    }, [])

    return (
        <svg className={className} style={style} viewBox="0 0 2660 1783" height="1783" preserveAspectRatio={ratio > 1.49 ? "xMinYMid meet" : "xMidYMid slice"}>
            <defs>
                <filter id="shadow">
                    <feDropShadow dx="0" dy="0" stdDeviation="20" floodColor="black" floodOpacity="0.4" />
                </filter>
            </defs>
            <g transform={ratio > 1.49 ? "translate(-160)" : ""}>
                {!scientistLoaded ? <image xlinkHref={require("../../img/osmosis-scientist-compressed.png").default} x="0" y="0" width="2660" height="1783"/> : null}
                <image ref={scientistRef} xlinkHref={require("../../img/osmosis-scientist.png").default} x="0" y="0" width="2660" height="1783"/>
                <rect x="-3000" y="1423" width="8660" height="360" fill="#120644" filter="url(#shadow)"/>
                {!frontLoaded ? <image xlinkHref={require("../../img/osmosis-scientist-front-compressed.png").default} x="0" y="0" width="2660" height="1783"/> : null}
                <image ref={frontRef} xlinkHref={require("../../img/osmosis-scientist-front.png").default} x="0" y="0" width="2660" height="1783"/>
            </g>
        </svg>
    )
}
