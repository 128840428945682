import './App.css';

import { OsmosisIcon } from "./components/osmosis-icon";
import { OsmosisWordEffect} from "./components/word-effect";

import "./img/osmosis-scientist.png";
import "./img/osmosis-scientist-front.png";
import {OsmosisScientist} from "./components/osmosis-scientist";
import { useEffect, useState } from 'react';

function App() {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimation(true);
    }, 500);
  }, []);

  return (
    <div className={`App ${animation ? "animation" : ""}`}>
      <header className="App-header">
        <div
          className="scientistContainer"
        >
          <OsmosisScientist className="scientist"/>
        </div>
        <div
          className="content"
        >
          <div className="logo">
            <OsmosisIcon/>
            <OsmosisWordEffect className="words"/>
          </div>
          <div className="comingSoon" style={{marginTop: "3rem"}}>
            <a href="https://app.osmosis.zone" target="_self">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="193"
                  height="18"
                  fill="none"
                  viewBox="0 0 193 18"
              >
                <path
    fill="#fff"
    d="M10.48 2.966V1.634H1.608V14h8.874v-1.332H3.029V8.366h6.714V7.034H3.029V2.966h7.452zM15.773 14h1.404V3.938L24.844 14h1.404V1.634h-1.404v10.062L17.176 1.634h-1.404V14zm19.737 0h1.404V2.966h4.158V1.634h-9.72v1.332h4.158V14zM54.785 2.966V1.634h-8.874V14h8.874v-1.332h-7.452V8.366h6.714V7.034h-6.714V2.966h7.452zm14.597 2.628c0-2.322-1.998-3.96-4.553-3.96h-5.022V14h1.404V9.554h3.276L67.619 14h1.602l-3.205-4.572c1.962-.414 3.366-1.89 3.366-3.834zM61.21 2.966h3.366c2.017 0 3.385.972 3.385 2.628 0 1.656-1.368 2.628-3.385 2.628h-3.365V2.966zM85.5 14h1.404V2.966h4.158V1.634h-9.72v1.332H85.5V14zm19.096-12.366v5.4h-7.29v-5.4h-1.404V14h1.404V8.366h7.29V14H106V1.634h-1.404zm15.728 1.332V1.634h-8.874V14h8.874v-1.332h-7.452V8.366h6.714V7.034h-6.714V2.966h7.452zm14.057 9.702V1.634h-1.404V14h8.154v-1.332h-6.75zM155.832 14h1.53l-5.454-12.366h-1.53L144.924 14h1.53l1.242-2.808h6.894L155.832 14zm-7.56-4.14l2.88-6.498 2.862 6.498h-5.742zM162.238 14h5.886c2.142 0 3.672-1.404 3.672-3.384 0-1.35-.828-2.466-2.25-3.186 1.116-.648 1.764-1.404 1.764-2.718 0-1.8-1.404-3.078-3.402-3.078h-5.67V14zm1.404-7.128v-3.96h3.906c1.368 0 2.34.828 2.34 1.98 0 1.152-.972 1.98-2.34 1.98h-3.906zm0 5.85V8.15h4.122c1.53 0 2.61.954 2.61 2.286s-1.08 2.286-2.61 2.286h-4.122zM184.618 4.194a.797.797 0 00-.786.548.8.8 0 00.303.909L188.824 9l-4.689 3.348a.791.791 0 00-.334.839.795.795 0 00.66.617.804.804 0 00.604-.154l5.6-4a.803.803 0 00.246-1.017.803.803 0 00-.246-.285l-5.6-4a.801.801 0 00-.447-.155z"
    />
              </svg>
            </a>
          </div>
          <div className="links">
            <a href="https://medium.com/osmosis" target="_blank" rel="noreferrer">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  fill="#fff"
                  strokeLinejoin="round"
                  strokeMiterlimit="2"
                  clipRule="evenodd"
                  viewBox="0 0 30 30"
              >
                <path
                  fillRule="nonzero"
                  d="M24 4H6a2 2 0 00-2 2v18a2 2 0 002 2h18a2 2 0 002-2V6a2 2 0 00-2-2zm-1.458 17h-5.931v-.333L18 19.445V12.47L14.589 21h-.533l-3.806-8.597v6.087l1.74 2.177V21H7.458v-.333l1.799-2.177v-7.242L7.658 9.249v-.244h4.376l3.399 7.353 2.932-7.353h4.154v.244L21 10.526v8.919l1.542 1.222V21z"
                />
              </svg>
            </a>
            <a href="https://github.com/osmosis-labs/osmosis" target="_blank" rel="noreferrer">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  fill="#fff"
                  strokeLinejoin="round"
                  strokeMiterlimit="2"
                  clipRule="evenodd"
                  viewBox="0 0 30 30"
              >
                <path
                  fillRule="nonzero"
                  d="M53 3c-6.627 0-12 5.373-12 12 0 5.623 3.872 10.328 9.092 11.63a1.751 1.751 0 01-.092-.583v-2.051h-1.508c-.821 0-1.551-.353-1.905-1.009-.393-.729-.461-1.844-1.435-2.526-.289-.227-.069-.486.264-.451.615.174 1.125.596 1.605 1.222.478.627.703.769 1.596.769.433 0 1.081-.025 1.691-.121.328-.833.895-1.6 1.588-1.962-3.996-.411-5.903-2.399-5.903-5.098 0-1.162.495-2.286 1.336-3.233-.276-.94-.623-2.857.106-3.587 1.798 0 2.885 1.166 3.146 1.481A8.993 8.993 0 0153.495 9c1.036 0 2.024.174 2.922.483C56.675 9.17 57.763 8 59.565 8c.732.731.381 2.656.102 3.594.836.945 1.328 2.066 1.328 3.226 0 2.697-1.904 4.684-5.894 5.097C56.199 20.49 57 22.1 57 23.313v2.734c0 .104-.023.179-.035.268C61.641 24.676 65 20.236 65 15c0-6.627-5.373-12-12-12z"
                  transform="translate(-38)"
                />
              </svg>
            </a>
            <a href="https://twitter.com/osmosiszone" target="_blank" rel="noreferrer">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  fill="#fff"
                  strokeLinejoin="round"
                  strokeMiterlimit="2"
                  clipRule="evenodd"
                  viewBox="0 0 30 30"
              >
                <path
                  fillRule="nonzero"
                  d="M91 3c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12S97.627 3 91 3zm6.464 9.535c.006.133.009.265.009.397 0 4.068-3.095 8.756-8.756 8.756A8.697 8.697 0 0184 20.305c.241.029.486.042.735.042a6.157 6.157 0 003.821-1.318 3.08 3.08 0 01-2.875-2.137 3.072 3.072 0 001.39-.053 3.078 3.078 0 01-2.469-3.017v-.039c.415.231.889.369 1.394.385a3.077 3.077 0 01-.953-4.108 8.737 8.737 0 006.343 3.216 3.078 3.078 0 015.244-2.807 6.185 6.185 0 001.955-.747 3.09 3.09 0 01-1.354 1.703 6.15 6.15 0 001.768-.484 6.204 6.204 0 01-1.535 1.594z"
                  transform="translate(-76)"
                />
              </svg>
            </a>
            <a href="https://t.me/osmosis_chat" target="_blank" rel="noreferrer">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  fill="#fff"
                  strokeLinejoin="round"
                  strokeMiterlimit="2"
                  clipRule="evenodd"
                  viewBox="0 0 30 30"
              >
                <path d="M18.384,22.779c0.322,0.228 0.737,0.285 1.107,0.145c0.37,-0.141 0.642,-0.457 0.724,-0.84c0.869,-4.084 2.977,-14.421 3.768,-18.136c0.06,-0.28 -0.04,-0.571 -0.26,-0.758c-0.22,-0.187 -0.525,-0.241 -0.797,-0.14c-4.193,1.552 -17.106,6.397 -22.384,8.35c-0.335,0.124 -0.553,0.446 -0.542,0.799c0.012,0.354 0.25,0.661 0.593,0.764c2.367,0.708 5.474,1.693 5.474,1.693c0,0 1.452,4.385 2.209,6.615c0.095,0.28 0.314,0.5 0.603,0.576c0.288,0.075 0.596,-0.004 0.811,-0.207c1.216,-1.148 3.096,-2.923 3.096,-2.923c0,0 3.572,2.619 5.598,4.062Zm-11.01,-8.677l1.679,5.538l0.373,-3.507c0,0 6.487,-5.851 10.185,-9.186c0.108,-0.098 0.123,-0.262 0.033,-0.377c-0.089,-0.115 -0.253,-0.142 -0.376,-0.064c-4.286,2.737 -11.894,7.596 -11.894,7.596Z"/>
              </svg>
            </a>
            <a href="https://discord.gg/osmosis" target="_blank" rel="noreferrer">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  strokeLinejoin="round"
                  strokeMiterlimit="2"
                  clipRule="evenodd"
                  viewBox="0 0 30 30"
              >
                <path
                  fill="#fff"
                  fillRule="nonzero"
                  d="M60.105 4.898A58.568 58.568 0 0045.653.415a.222.222 0 00-.233.11 40.686 40.686 0 00-1.799 3.697c-5.457-.817-10.887-.817-16.232 0-.484-1.164-1.2-2.587-1.827-3.697a.23.23 0 00-.233-.11 58.415 58.415 0 00-14.451 4.483.2.2 0 00-.095.082C1.578 18.731-.944 32.144.293 45.391a.246.246 0 00.093.167c6.073 4.459 11.955 7.167 17.729 8.962a.23.23 0 00.249-.082 42.077 42.077 0 003.627-5.9.224.224 0 00-.123-.312 38.871 38.871 0 01-5.539-2.64.228.228 0 01-.022-.378 31.17 31.17 0 001.1-.862.22.22 0 01.229-.031c11.62 5.305 24.199 5.305 35.682 0a.22.22 0 01.232.028c.356.293.728.586 1.103.865a.228.228 0 01-.02.378 36.36 36.36 0 01-5.541 2.637.226.226 0 00-.12.315 47.169 47.169 0 003.624 5.897.226.226 0 00.249.085c5.801-1.795 11.684-4.503 17.757-8.962a.228.228 0 00.092-.164c1.481-15.315-2.479-28.618-10.497-40.412a.183.183 0 00-.092-.084zM23.726 37.325c-3.498 0-6.381-3.211-6.381-7.156 0-3.944 2.827-7.156 6.381-7.156 3.582 0 6.437 3.24 6.381 7.156 0 3.945-2.827 7.156-6.381 7.156zm23.592 0c-3.498 0-6.381-3.211-6.381-7.156 0-3.944 2.827-7.156 6.381-7.156 3.582 0 6.437 3.24 6.381 7.156 0 3.945-2.799 7.156-6.381 7.156z"
                  transform="translate(2.783 5.546) scale(.34414)"
                />
              </svg>
            </a>
            <a href="https://gov.osmosis.zone" target="_blank" rel="noreferrer">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  strokeLinejoin="round"
                  strokeMiterlimit="2"
                  clipRule="evenodd"
                  viewBox="0 0 30 30"
              >
                <path
                  fill="#fff"
                  fillRule="nonzero"
                  d="M192.016 280.787c47.505 0 86.015-38.51 86.015-86.016 0-47.505-38.51-86.015-86.015-86.015S106 147.266 106 194.771c0 47.506 38.511 86.016 86.016 86.016zM398.45 280.787c47.506 0 86.016-38.51 86.016-86.016 0-47.505-38.51-86.015-86.016-86.015-47.505 0-86.015 38.51-86.015 86.015 0 47.506 38.51 86.016 86.015 86.016zM604.889 280.787c47.506 0 86.016-38.51 86.016-86.016 0-47.505-38.51-86.015-86.016-86.015-47.505 0-86.015 38.51-86.015 86.015 0 47.506 38.51 86.016 86.015 86.016zM192.016 487.225c47.505 0 86.015-38.511 86.015-86.016s-38.51-86.016-86.015-86.016S106 353.704 106 401.209s38.511 86.016 86.016 86.016zM604.89 487.225c47.505 0 86.016-38.511 86.016-86.016s-38.511-86.016-86.016-86.016-86.016 38.511-86.016 86.016 38.511 86.016 86.016 86.016zM192.016 693.668c47.505 0 86.015-38.51 86.015-86.015 0-47.506-38.51-86.016-86.015-86.016S106 560.147 106 607.653c0 47.505 38.511 86.015 86.016 86.015zM398.45 693.668c47.506 0 86.016-38.51 86.016-86.015 0-47.506-38.51-86.016-86.016-86.016-47.505 0-86.015 38.51-86.015 86.016 0 47.505 38.51 86.015 86.015 86.015zM385.724 323.676L320.92 388.48c-7.029 7.029-7.029 18.426 0 25.456l64.804 64.803c7.029 7.03 18.426 7.03 25.456 0l64.803-64.803c7.03-7.03 7.03-18.427 0-25.456l-64.803-64.804c-7.03-7.029-18.427-7.029-25.456 0zM592.158 530.114l-64.803 64.804c-7.03 7.029-7.03 18.426 0 25.456l64.803 64.803c7.03 7.03 18.427 7.03 25.456 0l64.804-64.803c7.03-7.03 7.03-18.427 0-25.456l-64.804-64.804c-7.029-7.03-18.426-7.03-25.456 0z"
                  transform="matrix(.03252 0 0 .03252 2.043 1.954)"
                />
              </svg>
            </a>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
